import "./Home.css";
import { useContext, useEffect, useState } from "react";
import { Col, Row, Card, Button } from "antd";
import { AppContext } from "../../../context/AppContextProvider";
import {
  getDashboardSummary,
  sendFailedOdooResources,
} from "../../../network/network";
import CustomImageBox from "../../../components/CustomImageBox";
import { useNavigate } from "react-router-dom";
import {
  USER_TYPE_MANAGER,
  USER_TYPE_ADMIN_STAFF,
  USER_TYPE_SUPER_ADMIN,
} from "../../../configs/constants";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
const Home = () => {
  const { authUser, appConfig, setActiveMenu } = useContext(AppContext);
  const [dashboardData, setDashboardData] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    getDashboardSummary().then((response) => {
      if (response.data.success === true) {
        setDashboardData(response.data.data);
      }
    });
  }, []);

  const clickFailedOdooResources = () => {
    sendFailedOdooResources().then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        // Success
        NotificationWithIcon(
          "success",
          t("send_Failed_Odoo_Resources"),
          t("send_Failed_Odoo_Resources_successfully")
        );
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data?.message);
      }
    });
  };

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <h3 style={{ direction: appConfig.direction, margin: 0 }}>
          {appConfig.direction === "ltr"
            ? "Hi " + authUser.name + " 👋, Welcome to AnyPOS!"
            : "مرحبا " + authUser.name + " 👋 اهلا بك في AnyPOS!"}
        </h3>
      </Card>

      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?d_status=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/icons/active-customers.png"
              title="Active Customers"
              value={dashboardData.active_customers_count}
            />
          </div>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?d_status=99",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/icons/inactive-customers.png"
              title="Inactive Customers"
              value={dashboardData.inactive_customers_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?monthly_new=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/icons/monthly-new-customers.png"
              title="Monthly New Customers"
              value={dashboardData.monthly_new_customers_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?monthly_inactive=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/icons/monthly-inactive-customers.png"
              title="Monthly Inactive Customers"
              value={dashboardData.monthly_inactive_customers_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("subscriptions");
              navigate({
                pathname: "/subscriptions",
                search: "?d_sub_near_expiry=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/icons/subscriptions-near-expiry.png"
              title="Subscriptions Near Expiry"
              value={dashboardData.sub_near_expiry_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?d_status=2",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/icons/submiting-kyc.png"
              title="Submitting KYC"
              value={dashboardData.submitting_kyc_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?d_status=3",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/icons/under-review-companies.png"
              title="Under Review Companies"
              value={dashboardData.under_review_customers_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?d_status=6",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/icons/unpaid-invoices.png"
              title="Unpaid Invoices"
              value={dashboardData.unpaid_invoices_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?idle=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/icons/idle.png"
              title="Idle Customers"
              value={dashboardData.idle_customers_count}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?d_status=7",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/icons/wallet.png"
              title="Recharge Requests"
              value={dashboardData.recharge_requests_count}
            />
          </div>
        </Col>
        {/* if user is super admin, manager or staff who is support agent, show tickets.
            In other words, do not show to staff who is not support agent */}
        {authUser.type === USER_TYPE_SUPER_ADMIN ||
        authUser.type === USER_TYPE_MANAGER ||
        (authUser.type === USER_TYPE_ADMIN_STAFF &&
          authUser.is_support_agent === 1) ? (
          <>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
              <div
                onClick={() => {
                  setActiveMenu("helpdesk");
                  navigate({
                    pathname: "/helpdesk",
                    search: "?status=new_tickets&page=1",
                  });
                }}
                className="home_widget"
              >
                <CustomImageBox
                  image="images/icons/new-tickets.png"
                  title="New Tickets"
                  value={dashboardData.new_tickets_count}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
              <div
                onClick={() => {
                  setActiveMenu("helpdesk");
                  navigate({
                    pathname: "/helpdesk",
                    search: "?status=inprogress_tickets&page=1",
                  });
                }}
                className="home_widget"
              >
                <CustomImageBox
                  image="images/icons/in-progress-tickets.png"
                  title="In Progress Tickets"
                  value={dashboardData.in_progress_tickets_count}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
              <div
                onClick={() => {
                  setActiveMenu("helpdesk");
                  navigate({
                    pathname: "/helpdesk",
                    search: "?status=done_tickets&page=1",
                  });
                }}
                className="home_widget"
              >
                <CustomImageBox
                  image="images/icons/done-tickets.png"
                  title="Done Tickets"
                  value={dashboardData.done_tickets_count}
                />{" "}
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
              <div
                onClick={() => {
                  setActiveMenu("helpdesk");
                  navigate({
                    pathname: "/helpdesk",
                    search: "?status=closed_tickets&page=1",
                  });
                }}
                className="home_widget"
              >
                <CustomImageBox
                  image="images/icons/closed-tickets.png"
                  title="Closed Tickets"
                  value={dashboardData.closed_tickets_count}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
              <div
                onClick={() => {
                  setActiveMenu("helpdesk");
                  navigate({
                    pathname: "/helpdesk",
                    search: "?status=late_tickets&page=1",
                  });
                }}
                className="home_widget"
              >
                <CustomImageBox
                  image="images/icons/late-tickets.png"
                  title="Late Tickets"
                  value={dashboardData.late_tickets_count}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
              <div
                onClick={() => {
                  setActiveMenu("helpdesk");
                  navigate({
                    pathname: "/helpdesk",
                    search: "?status=delayed_tickets&page=1",
                  });
                }}
                className="home_widget"
              >
                <CustomImageBox
                  image="images/icons/delayed-tickets.png"
                  title="Delayed Tickets"
                  value={dashboardData.delayed_tickets_count}
                />
              </div>
            </Col>
          </>
        ) : (
          ""
        )}
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <div
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies",
                search: "?failed_odoo_accounts=1",
              });
            }}
            className="home_widget"
          >
            <CustomImageBox
              image="images/icons/failed-odoo-accounts.png"
              title="Failed Odoo Accounts"
              value={dashboardData.failed_odoo_accounts}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <CustomImageBox
            image="images/icons/failed-odoo-invoices.png"
            title="Failed Odoo Invoices"
            value={dashboardData.failed_odoo_invoices}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <CustomImageBox
            image="images/icons/failed-odoo-payments.png"
            title="Failed Odoo Payments"
            value={dashboardData.failed_odoo_payments}
          />
        </Col>
      </Row>
      {authUser.type === USER_TYPE_SUPER_ADMIN && (
        <div style={{ marginTop: "20px" }}>
          <Button
            key="open-add-employee-modal"
            type="primary"
            onClick={clickFailedOdooResources}
          >
            {t("send_Failed_Odoo_Resources")}
          </Button>
        </div>
      )}
    </>
  );
};

export default Home;
