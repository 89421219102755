import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Table,
  Tag,
  Button,
  Form,
  Pagination,
  Input,
  Checkbox,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  EyeOutlined,
  HomeOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import { getSubscriptionsList } from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import {
  PERIOD_YEARLY,
  PLAN_TYPE_BASIC,
  PLAN_TYPE_PRO,
} from "../../../configs/constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { currencyFormatter } from "../../../utils";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../../context/AppContextProvider";

const Subscriptions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const urlPage = parseInt(query.get("page")) || 1;
  const urlStatusValue =
    parseInt(query.get("d_sub_near_expiry")) ||
    parseInt(query.get("sub_near_expiry"));

  const { setActiveMenu } = useContext(AppContext);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [SubscriptionsData, setSubscriptionsData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [paginationPage, setPaginationPage] = useState(urlPage);
  const [searchParams, setSearchParams] = useState([]);
  const [subNearExpiry, setSubNearExpiry] = useState([]);
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const [searchFormRef] = Form.useForm();

  useEffect(() => {
    setIsDataLoading(true);
    const params = new URLSearchParams();

    searchParams.company && params.append("company", searchParams.company);
    searchParams.sub_near_expiry
      ? searchParams.sub_near_expiry &&
        params.append("sub_near_expiry", searchParams.sub_near_expiry)
      : urlStatusValue && params.append("sub_near_expiry", urlStatusValue);

    if (paginationPage > 0) {
      params.append("page", paginationPage);
    }

    if (params.size > 0) {
      navigate({
        pathname: "/subscriptions",
        search: "?" + params.toString(),
      });
    }

    if (urlStatusValue) {
      handleSubCheck(true);
      getSubscriptionsList({
        ...searchParams,
        sub_near_expiry: urlStatusValue,
        page: paginationPage,
      })
        .then((response) => {
          if (response.data.success) {
            setSubscriptionsData(response.data.data.subscriptions);
            setPaginationData(response.data.data.pagination);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    } else {
      getSubscriptionsList({
        ...searchParams,
        page: paginationPage,
      })
        .then((response) => {
          setSubscriptionsData(response.data.data.subscriptions);
          setPaginationData(response.data.data.pagination);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    }
  }, [paginationPage, searchParams, urlStatusValue, navigate]);

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  useEffect(() => {
    setPaginationPage(urlPage);
  }, [urlPage]);

  const handleSearch = (values) => {
    let search_params = false;
    if (values.company_name) {
      search_params = {
        company: values.company_name,
        ...search_params,
      };
    }
    if (subNearExpiry === 1) {
      search_params = {
        sub_near_expiry: subNearExpiry,
        ...search_params,
      };
    }

    if (search_params) {
      setSearchParams(search_params);
      setPaginationPage(1);
    }
  };

  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    setPaginationPage(1);
    handleSubCheck(false);
    setSearchParams([]);
    const params = new URLSearchParams();
    navigate({
      pathname: "/subscriptions",
      search: "?" + params.toString(),
    });
  };

  const handleSubCheck = (status) => {
    setSubNearExpiry(status === true ? 1 : 0);
    setChecked(status);
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "subscriptions",
      breadcrumbName: t("menu_subscriptions"),
      icon: <MoneyCollectOutlined />,
    },
  ];

  const tableColumnsData = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return (
          <p>
            {paginationData.current_page
              ? paginationData.per_page * (paginationData.current_page - 1) +
                (index + 1)
              : index + 1}
          </p>
        );
      },
    },
    {
      title: t("col_subscription_plan"),
      render: (subscription) => {
        return (
          <>
            {subscription.type === PLAN_TYPE_PRO ? (
              <Tag color="green">{subscription.name}</Tag>
            ) : (
              <Tag color="blue">{subscription.name}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: t("col_price"),
      render: (subscription) => {
        return (
          <>
            {currencyFormatter.format(
              subscription.amount +
                (subscription.license_amount - subscription.license_discount) *
                  subscription.user_licenses_count_all
            )}{" "}
            / {subscription.period === PERIOD_YEARLY ? t("year") : t("month")}
            {subscription.type !== PLAN_TYPE_BASIC &&
              subscription.is_trial === 0 && (
                <>
                  <br />
                  <small>
                    {t("users") + ": " + subscription.user_licenses_count_all}
                  </small>
                </>
              )}
            {subscription.is_trial === true && subscription.start_date && (
              <>
                <br />
                <small>
                  * {t("pay_on")}{" "}
                  {moment(subscription.start_date).format("MMM. D, YYYY")}{" "}
                </small>
              </>
            )}
          </>
        );
      },
    },
    {
      title: t("business_name"),
      render: (subscription) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setActiveMenu("companies");
              navigate({
                pathname: "/companies/" + subscription.company_id,
              });
            }}
          >
            {subscription.company ? subscription.company : "-"}
          </span>
        );
      },
    },
    {
      title: t("col_start_date"),
      dataIndex: "start_date",
      render: (start_date) => {
        return start_date ? moment(start_date).format("MMM. D, YYYY") : "-";
      },
    },
    {
      title: t("col_end_date"),
      dataIndex: "end_date",
      render: (end_date) => {
        return end_date ? moment(end_date).format("MMM. D, YYYY") : "-";
      },
    },
    {
      title: t("action"),
      render: (subscription) => {
        return (
          <>
            {subscription.is_trial === 1 && <Tag>Trial</Tag>}
            {subscription.invoice && (
              <>
                <Button
                  type="default"
                  size="small"
                  target="_blank"
                  href={
                    process.env.REACT_APP_API_ROOT +
                    "/companies/" +
                    subscription.invoice.company_id +
                    "/invoices/" +
                    subscription.invoice.id
                  }
                >
                  <EyeOutlined />
                  View Invoice
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];

  const searchForm = (
    <>
      <Form
        name="search-company-form"
        style={{ paddingBottom: "20px" }}
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="company_name" label={t("company")}>
          <Input
            placeholder={t("search_company_name_here")}
            style={{ width: "200px" }}
          />
        </Form.Item>

        <Form.Item name="sub_near_expiry" label={t("sub_near_expiry")}>
          <Checkbox
            onChange={(e) => {
              handleSubCheck(e.target.checked);
            }}
            checked={checked}
          />
        </Form.Item>

        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_subscriptions")}
          ghost={false}
          footer={searchForm}
        />
      </Card>
      <Card>
        <Table
          columns={tableColumnsData}
          dataSource={SubscriptionsData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
        {paginationData.total > paginationData.per_page && (
          <Pagination
            current={paginationPage}
            total={paginationData.total}
            pageSize={paginationData.per_page}
            onChange={handlePagination}
            showSizeChanger={false}
            style={{
              marginTop: "20px",
              textAlign: "right",
            }}
          />
        )}
      </Card>
    </>
  );
};

export default Subscriptions;
