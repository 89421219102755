import { Table, Tag, Button, Modal, Form, Input } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  GenerateDevicesInvoice,
  getCompany,
  invoicesPay,
} from "../../../network/network";
import {
  INVOICE_DETAIL_TYPE_SUBSCRIPTION,
  INVOICE_DETAIL_TYPE_LICENSE,
  INVOICE_DETAIL_TYPE_DEVICE_PAYMENT,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_UNPAID,
  INVOICE_DETAIL_TYPE_TOPUP,
} from "../../../configs/constants";
import { currencyFormatter } from "../../../utils";
import { useEffect, useState } from "react";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import moment from "moment";

const InvoicesTable = ({ company, reloadCallback, isDisabled = false }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [invoicesTableData, setInvoicesTableData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState([]);
  const [invoicesId, setInvoicesId] = useState([]);
  const [invoicesPayForm] = Form.useForm();

  useEffect(() => {
    // Set Loading true
    setLoading(true);
    getCompany(company.id)
      .then((response) => {
        let invoices = response.data.data.company.invoices;
        // This will hold all of the invoices being modified
        let invoices_data = [];
        invoices.forEach((invoice) => {
          // details will hold all details being simplified
          let details = [];
          invoice.details.forEach((detail) => {
            if (details.some((e) => e.item === detail.item)) {
              var objIndex = details.findIndex(
                (obj) => obj.item === detail.item
              );
              details[objIndex].quantity =
                details[objIndex].quantity + detail.quantity;
            } else {
              details.push(detail);
            }
          });
          invoice.details = details;
          invoices_data.push(invoice);
        });
        setInvoicesTableData(invoices_data);
      })
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  }, [company, setInvoicesTableData]);

  const invoicesTableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("invoice_ID"),
      dataIndex: "uid",
      render: (uid) => {
        return uid ? uid : "-";
      },
    },
    {
      title: t("col_items"),
      render: (invoices) => {
        return (
          <>
            {invoices.details.map((invoice, i) => {
              return (
                <>
                  {invoice.type === INVOICE_DETAIL_TYPE_SUBSCRIPTION && (
                    <Tag color="green" key={i}>
                      {invoice.item}
                    </Tag>
                  )}

                  {invoice.type === INVOICE_DETAIL_TYPE_LICENSE && (
                    <Tag color="blue" key={i}>
                      {invoice.item} x {invoice.quantity}
                    </Tag>
                  )}

                  {invoice.type === INVOICE_DETAIL_TYPE_DEVICE_PAYMENT && (
                    <Tag color="orange" key={i}>
                      {invoice.item} x {invoice.quantity}
                    </Tag>
                  )}

                  {invoice.type === INVOICE_DETAIL_TYPE_TOPUP && (
                    <Tag color="orange" key={`${invoice.id}-${i}`}>
                      {invoice.item}
                    </Tag>
                  )}
                </>
              );
            })}
          </>
        );
      },
    },
    {
      title: t("total_amount_charged"),
      render: (invoices) => {
        return currencyFormatter.format(invoices.amount_charged);
      },
    },
    {
      title: t("date"),
      dataIndex: "created_at",
      render: (created_at) => {
        return created_at ? moment(created_at).format("MMM. D, YYYY") : "-";
      },
    },
    {
      title: t("col_status"),
      render: (invoices) => {
        return (
          <>
            {invoices.status === INVOICE_STATUS_PAID && (
              <Tag color="green">Paid</Tag>
            )}
            {invoices.status === INVOICE_STATUS_UNPAID && (
              <Tag color="red">Unpaid</Tag>
            )}
          </>
        );
      },
    },
    {
      title: t("reference_no"),
      dataIndex: "stcpay_reference_id",
      render: (stcpay_reference_id) => {
        return stcpay_reference_id ? stcpay_reference_id : "-";
      },
    },
    {
      title: t("payment_type"),
      dataIndex: "payment_brand",
      render: (payment_brand) => {
        return payment_brand ? payment_brand : "-";
      },
    },
    {
      title: t("invoices"),
      render: (invoices) => {
        return (
          <>
            {invoices.status === INVOICE_STATUS_UNPAID && (
              <Button
                type="primary"
                style={{
                  backgroundColor: "#2e7c08",
                  border: "#2e7c08",
                  marginBottom: "10px",
                }}
                size="small"
                onClick={() => {
                  setModalVisible(true);

                  setModalTitle(t("pay_invoice"));

                  setInvoicesId(invoices.id);
                }}
                // disabled={isDisabled}
              >
                {t("pay_now")}
              </Button>
            )}
            <div>
              <Button
                type="default"
                size="small"
                target="_blank"
                href={
                  process.env.REACT_APP_API_ROOT +
                  "/companies/" +
                  company.id +
                  "/invoices/" +
                  invoices.id
                }
                // disabled={isDisabled}
              >
                <EyeOutlined />
                View Invoice
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const handleDevicesInvoice = (company_id) => {
    // Set Loading true
    setLoading(true);
    GenerateDevicesInvoice(company_id)
      .then((response) => {
        if (response.data.success === true) {
          NotificationWithIcon(
            "success",
            t("generate_invoice_request_accepted"),
            t("generate_invoice_request_accepted_successfully")
          );
          // Reload Callback
          reloadCallback();
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  };

  const handleCancel = () => {
    // Reset Form Fields
    invoicesPayForm.resetFields();
    setModalVisible(false);
  };
  const handlePay = () => {
    invoicesPayForm.validateFields().then((values) => {
      // Set Loading true
      setLoading(true);
      invoicesPay(invoicesId, values)
        .then((response) => {
          let data = response.data || response.response.data;
          if (data.success === true) {
            NotificationWithIcon(
              "success",
              t("invoice_paid"),
              t("invoice_paid_successfully")
            );
            // Reload Callback
            reloadCallback();
          } else {
            let errors = data?.data?.errors;
            if (errors && Object.keys(errors).length) {
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                errors[Object.keys(errors)[0]]
              );
            }
          }
        })
        .finally(() => {
          // Reset Form Fields
          invoicesPayForm.resetFields();
          setModalVisible(false);
          // Set Loading False
          setLoading(false);
        });
    });
  };

  return (
    <>
      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button
          type="primary"
          onClick={() => {
            handleDevicesInvoice(company.id);
          }}
          disabled={isDisabled}
        >
          {t("generate_devices_invoice")}
        </Button>
      </div>
      <Table
        columns={invoicesTableColumns}
        dataSource={invoicesTableData}
        loading={loading}
        rowKey="id"
        pagination={false}
      />
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handlePay}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handlePay}
            // disabled={isDisabled}
          >
            {t("pay_now")}
          </Button>,
        ]}
      >
        <Form
          name="invoice_Pay-form"
          layout="vertical"
          onFinish={handlePay}
          style={{ width: "100%", margin: "0" }}
          form={invoicesPayForm}
        >
          <Form.Item
            name="manually_paid_reason"
            label={t("paid_reason")}
            rules={[
              {
                required: true,
                message: t("validate_paid_reason_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default InvoicesTable;
